import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

import Layout from '@common/Layout';
import { Container } from '@components/global';

const ThirdPage = () => {
  useEffect(() => {
    navigate('/privacy.pdf');
  }, []);

  return (
    <Layout>
      <Container>
        {/* You can add some content here if you want, or leave it empty */}
      </Container>
    </Layout>
  );
};

export default ThirdPage;
